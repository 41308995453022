import {CallToAction} from '@/components/CallToAction'
import {Faqs} from '@/components/Faqs'
import {Footer} from '@/components/Footer'
import {Header} from '@/components/Header'
import {Hero} from '@/components/Hero'
import {Pricing} from '@/components/Pricing'
import {PrimaryFeatures} from '@/components/PrimaryFeatures'
import {SecondaryFeatures} from '@/components/SecondaryFeatures'
import {Testimonials} from '@/components/Testimonials'
import {NextSeo} from "next-seo";
import {DemoCta} from "@/components/DemoCta";

export default function Home() {
    return (
        <>
            <NextSeo
                title="Bijles voor en door leerlingen of studenten"
                description="Vergroot kennisoverdracht en verklein kansenongelijkheid. Breng vraag en aanbod van bijles samen, onder regie van onderwijsinstellingen."
            />
            <Header/>
            <main>
                <Hero/>
                <CallToAction/>
                <DemoCta hideFeatures/>
                <PrimaryFeatures/>
                <SecondaryFeatures/>
                {/*<Testimonials/>*/}
                <Pricing/>
                <Faqs/>
            </main>
            <Footer/>
        </>
    )
}
