import Image from 'next/image'

import {Button} from '@/components/Button'
import {Container} from '@/components/Container'
import backgroundImage from '@/images/background-call-to-action.jpg'
import Link from "next/link";
import {
    ArrowPathIcon, ArrowRightIcon, ArrowTopRightOnSquareIcon,
    CloudArrowUpIcon, CodeBracketIcon,
    Cog6ToothIcon, CurrencyEuroIcon, EyeIcon, FaceSmileIcon,
    FingerPrintIcon, GlobeAltIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'

const features = [
    {
        name: 'Binnen 1 dag online',
        description: '',
        icon: GlobeAltIcon,
    },
    {
        name: 'Stimuleert leerplezier',
        description: '',
        icon: FaceSmileIcon,
    },
    {
        name: 'Overzichtelijk en goedkoop',
        description: '',
        icon: EyeIcon,
    },
    {
        name: 'Valt onder NPO-gelden',
        description: '',
        icon: CurrencyEuroIcon,
    },
    {
        name: 'Maatwerk mogelijk',
        description: '',
        icon: CodeBracketIcon,
    },
]

export function CallToAction() {
    return (
        <section
            id="waarom-bijlesplein"
            className="relative overflow-hidden bg-blue-600 py-32"
        >
            <Image
                className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
                src={backgroundImage}
                alt=""
                width={2347}
                height={1244}
                unoptimized
            />
            <Container className="relative">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
                        Waarom Bijlesplein?
                    </h2>
                </div>
            </Container>

            <div className="mx-auto max-w-7xl px-6 lg:px-8 my-10">
                <dl className="mx-auto flex flex-wrap justify-center max-w-2xl grid-cols-1 gap-6 text-base leading-7 text-white sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-5 lg:gap-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9 whitespace-nowrap">
                            <dt className="inline font-semibold text-white">
                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-white" aria-hidden="true"/>
                                {feature.name}
                            </dt>
                            {' '}
                            <dd className="inline">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>

            <Container className="relative">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="mt-5 text-lg  text-white">
                        Als onderwijsinstelling wil je de vraag naar bijles zelf regelen om een goed alternatief te bieden
                        voor commerciële bijlesaanbieders. Het heeft meerdere voordelen wanneer leerlingen en studenten zelf
                        elkaar na lestijd helpen. Meer leerplezier, eigenaarschap van het eigen leren en sociale binding met elkaar.
                        Inmiddels is bewezen dat het een positief effect heeft wanneer leerlingen van elkaar leren.
                        <a className="hover:opacity-70"
                           href="https://nos.nl/nieuwsuur/artikel/2408491-onderwijsraad-waarschuwt-voor-kansenongelijkheid-door-private-bijles"
                           target="_blank" rel="noreferrer"> Artikel over kansengelijkheid. <ArrowTopRightOnSquareIcon className="inline-flex h-4 w-4 text-white mb-1"/></a>
                    </p>



                    <h2 className="font-display text-2xl tracking-tight text-white mt-10 mb-1">
                        Kansongelijkheid
                    </h2>

                    <p className="text-lg text-white">
                        Als onderwijsinstelling heb je ook een verantwoordelijkheid als het gaat om kansongelijkheid. Commerciële
                        bijles is alleen bereikbaar voor ouders met een goed inkomen. Met Bijlesplein stimuleer je bijles tegen
                        een laag tarief. Het is een kant-en-klaar platform waar leerlingen en studenten een mede-scholier kunnen vinden
                        voor bijles en waar de leerlingen en studenten hun diensten kunnen aanbieden.
                    </p>

                    <div className="space-x-6 mb-6">
                        <Button href="/register" color="white" className="mt-10" contact/>
                        <Link
                            className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900"
                            href="/demo">Vraag gratis demo aan</Link>
                    </div>

                    <Link href="/tips-bijles-organiseren" className="text-white hover:opacity-70 font-semibold text-lg">
                        5 tips voor betere bijles <ArrowRightIcon className="w-4 h-4 mb-0.5 inline-flex" />
                    </Link>
                </div>
            </Container>
        </section>
    )
}
