import {TextField} from "@/components/Fields";
import {Button} from "@/components/Button";
import {useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {ChatBubbleLeftEllipsisIcon, ClockIcon, QuestionMarkCircleIcon} from "@heroicons/react/20/solid";

const features = [
    {
        name: 'Hoe werkt het?',
        description:
            'Je ontvangt binnen enkele minuten een email. Hiermee kun je inloggen op je eigen demo omgeving.',
        icon: QuestionMarkCircleIcon,
    },
    {
        name: 'Hoe lang werkt het?',
        description: 'De demo omgeving is zo lang als je wilt te gebruiken. Neem vooral de tijd om het systeem te leren kennen.',
        icon: ClockIcon,
    },
    {
        name: 'Ik heb een vraag.',
        description: 'Neem bij vragen vooral contact op met onze support desk. Je vindt deze op de contact pagina.',
        icon: ChatBubbleLeftEllipsisIcon,
    },
]

export function DemoCta({hideFeatures = false}) {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const onSubmit = data => {
        axios.post('https://beheer.bijlesplein.nl/api/demo/request', data)
            .then(function (response) {
                setError(null)
                setSuccess(true)
            })
            .catch(function (error) {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message)
                } else {
                    setError('Er ging iets mis bij het versturen van het formulier. Probeer het later opnieuw.')
                }
            });
    };

    return <div className="overflow-hidden bg-white pb-24 pt-10 sm:pb-32 sm:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 grid lg:grid-cols-2 gap-x-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20">
                <div className="lg:ml-auto lg:pt-4 lg:pl-4">
                    <div className="lg:max-w-lg">
                        <h2 className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Direct in je
                            mailbox</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-display">Vraag
                            vrijblijvend een demo aan</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Deze demo is gratis te gebruiken. Het is een exacte kopie van het echte systeem.
                        </p>
                        {!hideFeatures &&
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon
                                                className="absolute top-1 left-1 h-5 w-5 text-blue-600"
                                                aria-hidden="true"/>
                                            {feature.name}
                                        </dt>
                                        {' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        }
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-2xl mt-10 lg:mt-0">
                {error &&
                    <div className="rounded-md bg-red-50 p-4 mt-4 -mb-5">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor"
                                     className="h-5 w-5 text-red-400">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>

                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">{error}</h3>
                            </div>
                        </div>
                    </div>
                }

                {success ?
                    <div className="rounded-md bg-green-50 p-4 mt-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor"
                                     className="h-5 w-5 text-green-400">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>

                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">Aanvraag succesvol</h3>
                                <div className="mt-2 text-sm text-green-700">
                                    <p>Over enkele ogenblikken zul je een email ontvangen met de
                                        inloggegevens van je
                                        demo omgeving.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
                    >
                        <TextField
                            label="Voornaam"
                            id="first_name"
                            name="first_name"
                            type="text"
                            autoComplete="given-name"
                            required
                            register={register("first_name", {required: true})}
                        />
                        <TextField
                            label="Achternaam"
                            id="last_name"
                            name="last_name"
                            type="text"
                            autoComplete="family-name"
                            required
                            register={register("last_name", {required: true})}
                        />
                        <TextField
                            className="col-span-full"
                            label="Telefoonnummer"
                            id="phone"
                            name="phone"
                            type="phone"
                            autoComplete="phone"
                            register={register("phone", {required: false})}
                        />
                        <TextField
                            className="col-span-full"
                            label="Email"
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            register={register("email", {required: true})}
                        />
                        <div className="col-span-full">
                            <Button
                                type="submit"
                                variant="solid"
                                color="blue"
                                className="w-full"
                            >
                                          <span>
                                            Demo aanvragen <span aria-hidden="true">&rarr;</span>
                                          </span>
                            </Button>
                        </div>
                    </form>
                }
            </div>
        </div>
    </div>
}
